import React, { useEffect, useState } from "react";
import AvatarModelCard from "../AvatarModelCard";
import { useUserControllerFindOneQuery } from "../../Features/API/apiSlice.generated";
import { POST, PRE } from "../../Constants/questions";
import { defaultAvatarClient } from "../../Constants/avatarLibrary";

const Patient = ({
  id,
  patientAnswers,
  sessionQuestions,
  generatePDF,
  loading,
}) => {
  const [userSummaryLoading, setUserSummaryLoading] = useState(false);
  const [preSessionQuestions, setPreSessionQuestions] = useState([]);
  const [postSessionQuestions, setPostSessionQuestions] = useState([]);

  const currentDate = new Date();
  const date = currentDate.toLocaleDateString();

  const {
    data: patient,
    isLoading: patientDataLoading,
    isError: patientDataError,
  } = useUserControllerFindOneQuery(
    {
      id: id,
    },
    {
      skip: !id,
    }
  );

  const userOptions = {
    filename: `${patient?.displayName}_${date}.pdf`,
    page: {
      margin: 10,
      orientation: "landscape",
    },
  };

  const downloadUserPdf = async (id: any) => {
    setUserSummaryLoading(true);
    const getTargetElement = () =>
      document.getElementById(`print-user-container-${id}`);
    await generatePDF(getTargetElement, userOptions as any);
    setUserSummaryLoading(false);
  };

  useEffect(() => {
    if (sessionQuestions && patientAnswers) {
      // Helper function to get the latest answer for each question
      const getLatestAnswer = (answers) => {
        return answers.reduce((acc, answer) => {
          acc[answer.questionId] = answer;
          return acc;
        }, {});
      };

      // Separate questions by their time (PRE or POST)
      const preQuestions = sessionQuestions.filter(
        (q) => q.questionTime === "PRE"
      );
      const postQuestions = sessionQuestions.filter(
        (q) => q.questionTime === "POST"
      );

      // Get the latest answers
      const latestAnswers = getLatestAnswer(patientAnswers);

      // Function to combine questions and answers
      const combineQuestionsAndAnswers = (questions, answers) => {
        return questions.map((question) => {
          const answer = answers[question._id];
          return {
            question: question.question,
            answer: answer ? answer.answer : "",
          };
        });
      };
      setPreSessionQuestions(
        combineQuestionsAndAnswers(preQuestions, latestAnswers)
      );
      setPostSessionQuestions(
        combineQuestionsAndAnswers(postQuestions, latestAnswers)
      );
    }
  }, [sessionQuestions, patientAnswers]);

  const admissionDate: Date =
    !patientDataLoading && patient?.createdAt
      ? new Date(patient.createdAt)
      : new Date();

  const formattedDate =
    admissionDate &&
    admissionDate.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

  return (
    <>
      {!patientDataLoading && !patientDataError && patient && (
        <div
          key={id}
          className="bg-gray-100 rounded-lg shadow-md p-8 mb-10 flex flex-col justify-center"
        >
          <div className="p-4" id={`print-user-container-${id}`}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
              <div className="flex flex-col flex-wrap gap-4">
                <div className="flex flex-col gap-1">
                  <p className="xl:text-lg 3xl:text-xl">
                    <span className="font-semibold">Name:</span>{" "}
                    {patient?.displayName}
                  </p>
                  <p className="xl:text-lg 3xl:text-xl">
                    <span className="font-semibold">Address: </span> 915 Middle
                    River Dr suite 114, Fort Lauderdale, FL 33304
                  </p>
                  <p className="xl:text-lg 3xl:text-xl capitalize">
                    <span className="font-semibold">Primary Diagnosis: </span>
                    Substance Use Disorder
                  </p>
                  <p className="xl:text-lg 3xl:text-xl">
                    <span className="font-semibold">Facility Location: </span>{" "}
                    Lauderdale Recovery Unplugged Fort
                  </p>
                  <p className="xl:text-lg 3xl:text-xl">
                    <span className="font-semibold ">Date of Admission: </span>{" "}
                    {formattedDate}
                  </p>
                </div>
                <AvatarModelCard
                  url={patient?.avatarUrl || defaultAvatarClient}
                />
              </div>
              <div className="flex flex-col flex-wrap gap-6">
                <div>
                  <h3 className="xl:text-lg 3xl:text-xl font-semibold mb-2">
                    Pre-Session Questions:
                  </h3>
                  <ul>
                    {preSessionQuestions &&
                      preSessionQuestions.length > 0 &&
                      preSessionQuestions.map((item: any) => (
                        <li>
                          <p className="leading-loose">
                            <span className="xl:text-lg 3xl:text-xl">
                              {" "}
                              {item?.question}{" "}
                            </span>
                            <span className="font-semibold xl:text-lg 3xl:text-xl">
                              {" "}
                              {item?.answer !== "" ? item?.answer : "-"}{" "}
                            </span>
                          </p>
                        </li>
                      ))}
                  </ul>
                </div>
                <div>
                  <h3 className="xl:text-lg 3xl:text-xl font-semibold mb-2">
                    Post-Session Questions:
                  </h3>
                  <ul>
                    {postSessionQuestions &&
                      postSessionQuestions.length > 0 &&
                      postSessionQuestions.map((item: any) => (
                        <li>
                          <p className="leading-loose">
                            <span className="xl:text-lg 3xl:text-xl">
                              {" "}
                              {item?.question}{" "}
                            </span>
                            <span className="font-semibold xl:text-lg 3xl:text-xl">
                              {" "}
                              {item?.answer !== "" ? item?.answer : "-"}{" "}
                            </span>
                          </p>
                        </li>
                      ))}
                  </ul>
                </div>
                <div>
                  <h3 className="xl:text-lg 3xl:text-xl font-semibold mb-2">
                    Emojis Used:
                  </h3>
                  <ul>
                    <li>
                      <p className="xl:text-xl 3xl:text-2xl flex items-center gap-1">
                        <span>😄</span>:
                        <span className="px-1 font-semibold">4</span>
                      </p>
                    </li>
                    <li>
                      <p className="xl:text-xl 3xl:text-2xl flex items-center gap-1">
                        <span>👋</span>:
                        <span className="px-1 font-semibold">2</span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex flex-col my-3">
              <label className="text-lg font-semibold mb-3" title="notes">
                {" "}
                Notes:{" "}
              </label>
              <textarea
                className="border border-gray-200 p-2 rounded-md"
                name="notes"
                id="notes"
                cols={30}
                rows={5}
              ></textarea>
            </div>
          </div>
          {
            <button
              onClick={() => downloadUserPdf(id)}
              disabled={userSummaryLoading}
              className={`h-12 w-[15rem] text-sm bg-primary text-white font-semibold rounded-lg py-3 px-4 my-3 mx-auto ${
                userSummaryLoading && "opacity-80"
              } ${loading && "hidden"}`}
            >
              {userSummaryLoading ? "Downloading..." : "Download Summary"}
            </button>
          }
        </div>
      )}
    </>
  );
};

export default Patient;
