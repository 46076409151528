import React, { useEffect, useState } from "react";
interface Prop {
    url?: string;
    avatarPngUrl?: string;
    showConfirm?: boolean;
    selectedAvatar?: any;
    handleSelect?: any;
    handleConfirm?: any;
    type?: string;
}

 function AvatarModelCard({ url, avatarPngUrl, showConfirm, selectedAvatar, handleSelect, handleConfirm, type }: Prop) {
    const pngUrl = url
      ?.replace("https://models.readyplayer.me/", "https://api.readyplayer.me/v1/avatars/")
      .replace(".glb", ".png");

    const [avatarUrls, setAvatarUrls] = useState({
        default: "",
        selected: ""
    })

    const [avatarPngUrls, setAvatarPngUrls] = useState({
        default: "",
        selected: ""
    })

    useEffect(() => {
        if(pngUrl && type) {
            const defaultUrl = `${pngUrl}?expression=happy`;
            const selectedUrl = `${pngUrl}?expression=lol&pose=${type==="serious" ? "power-stance" : "thumbs-up"}`;

             // Preload the images
            const defaultImg = new Image();
            defaultImg.src = defaultUrl;

            const selectedImg = new Image();
            selectedImg.src = selectedUrl;

            setAvatarUrls({
                default: defaultUrl,
                selected: selectedUrl
            });
        }
        if(avatarPngUrl && type) {
            const defaultAvatarPngUrl = `${avatarPngUrl}?expression=happy`;
            const selectedAvatarPngUrl = `${avatarPngUrl}?expression=lol&pose=${type==="serious" ? "power-stance" : "thumbs-up"}`;

            // Preload the images
            const defaultPngImg = new Image();
            defaultPngImg.src = defaultAvatarPngUrl;

            const selectedPngImg = new Image();
            selectedPngImg.src = selectedAvatarPngUrl;

            setAvatarPngUrls({
                default: defaultAvatarPngUrl,
                selected: selectedAvatarPngUrl
            });
        }
    }, [url, avatarPngUrl, type])

    return (
        <div onClick={() => handleSelect(url, pngUrl)} className="relative m-2 w-[10rem] h-[10rem] lg:h-[15rem] lg:w-[15rem] cursor-pointer p-2">
            {showConfirm && selectedAvatar === pngUrl &&  (
                <button
                    type="button"
                    className="absolute bottom-4 -translate-x-1/2 left-1/2 bg-primary text-white text-xs rounded-lg px-4 py-2"
                    onClick={() => handleConfirm(url)}
                >
                    Confirm
                </button>
            )}
            <img
                className={
                        selectedAvatar === (url ? pngUrl : avatarPngUrl) 
                        ? "border border-primary rounded-md shadow-custom" 
                        : "transition-all"
                    }
                    src={
                       type === undefined ? `${pngUrl}?expression=happy` : pngUrl
                            ? selectedAvatar === pngUrl
                                ? avatarUrls?.selected
                                : avatarUrls?.default
                            : selectedAvatar === avatarPngUrl
                            ? avatarPngUrls?.selected
                            : avatarPngUrls?.default
                    }
                alt={pngUrl ? pngUrl : avatarPngUrl}
            />
        </div>
    );
}

export default React.memo(AvatarModelCard)