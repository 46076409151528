import { useMediaDevice } from "@videosdk.live/react-sdk";
import React, { useState, useRef, useEffect } from "react";
import Heading from "../../Components/Heading";
import { Button } from "../../Components/Button";
import { Dialog, Transition } from "@headlessui/react";
import Loader from "../../Components/Loader";
import MicrophoneTestComponent from "../../Components/MicTest";
import { useAppSelector } from "../../Store/store";
import { GetUser } from "../../Features/Auth/userSlice";
import { USER_ROLES } from "../../Constants/roles";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMeet } from "../../Hooks/useMeet";
import { getVidSdkAuth } from "../../Features/VideoSdkAuth/videoSdkAuthSlice";
import { useNotification } from "../../Hooks/useNotification";
import { NotificationType } from "../../Constants/notificationType.enum";
import Paragraph from "../../Components/Paragraph";
import AvatarModelCard from "../../Components/AvatarModelCard";
import {
  defaultAvatarClient,
  defaultAvatarProvider,
} from "../../Constants/avatarLibrary";

const SettingsComponent: React.FC = () => {
  const { data: user } = useAppSelector(GetUser);
  const userRole = user?.gtUserData?.gtUserType;

  const avatarUrl =
    !user?.avatarUrl ||
    user?.avatarUrl.includes("themirror://avatar/astronaut-male")
      ? userRole === USER_ROLES.PROVIDER
        ? defaultAvatarProvider
        : defaultAvatarClient
      : user?.avatarUrl;

  const { data: tokenObj } = useAppSelector(getVidSdkAuth);
  const token = (tokenObj as any)?.token || "";

  const navigate = useNavigate();
  const { notify } = useNotification();

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const sessionId: any = searchParams.get("sessionId");

  const [loading, setLoading] = useState(false);
  const [speakersTested, setSpeakersTested] = useState<boolean>(false);
  const [microphoneTested, setMicrophoneTested] = useState<boolean>(false);
  const [isSpeakerModalOpen, setIsSpeakerModalOpen] = useState<boolean>(false);
  const [isMicrophoneModalOpen, setIsMicrophoneModalOpen] =
    useState<boolean>(false);

  const [microphones, setMicrophones] = useState<Array<any>>([]);
  const [selectedMicrophone, setSelectedMicrophone] = useState<string>("");
  const [cameras, setCameras] = useState<Array<any>>([]);
  const [selectedCamera, setSelectedCamera] = useState<string>("");

  const videoRef = useRef<HTMLVideoElement>(null);
  const { handleAddPatient, handleUpdatePatient, validateMeeting } = useMeet();
  const { getCameras, getMicrophones } = useMediaDevice();

  useEffect(() => {
    const fetchDevices = async () => {
      const camerasList = await getCameras();
      const microphonesList = await getMicrophones();

      setMicrophones(microphonesList);
      setCameras(camerasList);

      if (camerasList?.length > 0) {
        setSelectedCamera(camerasList[0]?.deviceId);
        sessionStorage.setItem("selectedCamera", camerasList[0]?.deviceId);
      }
      if (microphonesList?.length > 0) {
        setSelectedMicrophone(microphonesList[0]?.deviceId);
        sessionStorage.setItem("selectedMic", microphonesList[0]?.deviceId);
      }
    };

    fetchDevices();
  }, []);

  useEffect(() => {
    if (selectedCamera) {
      navigator.mediaDevices
        .getUserMedia({ video: { deviceId: selectedCamera } })
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        })
        .catch((error) => {
          console.error("Error accessing camera:", error);
        });
    }
  }, [selectedCamera]);

  useEffect(() => {
    const checkValidity = async () => {
      const isMeetingValid = await validateMeeting({ roomId: id, token });
      if (!isMeetingValid) {
        navigate("/");
      }
    };
    token && checkValidity();
    // Access user's camera
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      })
      .catch((error) => {
        console.error("Error accessing camera:", error);
      });

    // Cleanup function to stop the camera when the component unmounts
    return () => {
      if (videoRef.current) {
        const stream = videoRef.current.srcObject as MediaStream;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
      }
    };
  }, [id, token]);

  const testSpeaker = async () => {
    const audioFilePath = "/audio/test-speaker.mp3";

    return new Promise<boolean>((resolve) => {
      const audio = new Audio(audioFilePath);
      // Event listener to detect when the audio has finished playing
      audio.addEventListener("ended", () => {
        audio.pause();
        audio.currentTime = 0;
        resolve(true);
        setLoading(false);
      });

      audio.play();
      setLoading(true);
    });
  };

  const handleTestSpeakers = async () => {
    setIsSpeakerModalOpen(true);
    await testSpeaker();
  };

  const handleSpeakerModalClose = (userHeard: boolean) => {
    setIsSpeakerModalOpen(false);
    if (!userHeard) {
      setSpeakersTested(false);
    } else {
      setSpeakersTested(true);
    }
  };

  const handleTestMicrophone = () => {
    setIsMicrophoneModalOpen(true);
  };

  const handleMicrophoneModalClose = (userHeard: boolean) => {
    setIsMicrophoneModalOpen(false);

    if (userHeard) {
      setMicrophoneTested(true);
    }
  };

  const handleContinue = async () => {
    if (userRole === USER_ROLES.CLIENT) {
      await handleAddPatient(sessionId);
      window.location.assign(`/questions?room=${id}&sessionId=${sessionId}`);
    } else {
      window.location.assign(`/session/${id}?sessionId=${sessionId}`);
    }
  };

  const handleSettingsReset = () => {
    setSpeakersTested(false);
    setMicrophoneTested(false);
  };

  return (
    <div className="w-full max-w-5xl overflow-hidden pb-10">
      {/* <Heading level={2} className='text-center my-3'>
        Verify Your System
      </Heading> */}

      <div className="flex flex-col md:flex-row items-center justify-center md:items-start md:justify-evenly gap-6">
        <div className="flex-1 flex justify-center flex-col items-center w-full lg:mr-10 md:mr-4 mr-0 mt-0 md:mt-14">
          <Heading level={3}> Avatar </Heading>
          <AvatarModelCard url={avatarUrl} />
          <Button className="lg:w-[15rem] w-auto mx-auto mt-5 md:mt-[5rem] border-primaryLight hover:bg-primary hover:text-white">
            <Link
              to={
                userRole === USER_ROLES.CLIENT
                  ? `/avatar/modify?role=client&id=${id}`
                  : `/avatar/modify?role=provider&id=${id}`
              }
              state={{ id: id }}
            >
              Modify Avatar
            </Link>
          </Button>
        </div>
        <div className="flex flex-col justify-center items-center mr-0 md:mr-3 lg:mr-6">
          <Heading level={3} className="text-left mt-14">
            {" "}
            Audio Video{" "}
          </Heading>
          <div className="lg:h-[15rem] rounded-lg w-full h-[15rem] my-1 flex justify-center items-center">
            <video
              className="rounded-lg w-[15rem] h-[15rem]"
              ref={videoRef}
              autoPlay
              playsInline
              muted
            />
          </div>
          <select
            title="cameras"
            value={selectedCamera}
            onChange={(e) => {
              setSelectedCamera(e.target.value);
              sessionStorage.setItem("selectedCamera", e.target.value);
            }}
            className="my-2 p-2 border rounded"
          >
            {cameras?.map((camera) => (
              <option key={camera?.deviceId} value={camera?.deviceId}>
                {camera?.label || `Camera ${camera?.deviceId}`}
              </option>
            ))}
          </select>
          <Button
            className="border-primaryLight hover:bg-primary hover:text-white w-auto md:w-full mt-10 md:mt-[2rem]"
            onClick={handleSettingsReset}
          >
            Reset Settings
          </Button>
        </div>
        <div className="flex flex-col justify-center items-center md:mt-14 mt-8 w-full">
          <Heading level={3} className="text-left mb-10">
            {" "}
            Start Session{" "}
          </Heading>

          <select
            title="microphones"
            value={selectedMicrophone}
            onChange={(e) => {
              setSelectedMicrophone(e.target.value);
              sessionStorage.setItem("selectedMic", e.target.value);
            }}
            className="mb-4 p-2 border rounded w-3/4"
          >
            {microphones?.map((microphone) => (
              <option key={microphone?.deviceId} value={microphone?.deviceId}>
                {microphone?.label || `Microphone ${microphone?.deviceId}`}
              </option>
            ))}
          </select>

          {/* Test Speaker Button */}
          <Button
            className="w-full md:w-[15rem] border-primaryLight hover:bg-primary hover:text-white"
            onClick={handleTestSpeakers}
            disabled={speakersTested}
          >
            Verify Speakers
          </Button>

          {/* Test Microphone Button */}
          <Button
            className="w-full md:w-[15rem] my-5 border-primaryLight hover:bg-primary hover:text-white"
            onClick={handleTestMicrophone}
            disabled={microphoneTested}
          >
            Verify Microphone
          </Button>

          <Paragraph className="pt-2 text-primaryLight font-semibold text-center text-sm lg:text-base">
            Please verify your audio settings before entering the session.
          </Paragraph>

          {/* Continue Button */}
          <Button
            className="mt-[3.5rem] lg:mt-[2.75rem] w-full border-primaryLight hover:bg-primary hover:text-white"
            onClick={handleContinue}
            disabled={!speakersTested || !microphoneTested}
          >
            Enter Session
          </Button>
        </div>
      </div>

      {/* Speaker Modal */}
      <Transition show={isSpeakerModalOpen} as={React.Fragment}>
        <Dialog
          open={isSpeakerModalOpen}
          onClose={() => handleSpeakerModalClose(false)}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          {loading ? (
            <Loader />
          ) : (
            <div className="fixed inset-0 flex items-center justify-center">
              <div className="bg-white px-[6rem] py-[5rem] max-w-lg mx-auto rounded shadow-md text-center">
                <Dialog.Title className="text-lg font-semibold">
                  Did you hear the audio?
                </Dialog.Title>
                <div className="mt-4 flex gap-6 justify-center">
                  <Button
                    className="border-primaryLight hover:bg-primary hover:text-white"
                    onClick={() => handleSpeakerModalClose(true)}
                  >
                    Yes
                  </Button>
                  <Button
                    className="border-primaryLight hover:bg-primary hover:text-white"
                    onClick={() => handleSpeakerModalClose(false)}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Dialog>
      </Transition>

      {/* Microphone Modal */}
      <Transition show={isMicrophoneModalOpen} as={React.Fragment}>
        <Dialog
          open={isMicrophoneModalOpen}
          onClose={() => handleMicrophoneModalClose(false)}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="fixed inset-0 flex items-center justify-center">
            <div className="bg-white px-[5.5rem] py-[5rem] max-w-lg mx-auto rounded shadow-md text-center">
              <Dialog.Title className="text-lg font-semibold">
                <p className="text-gray-400 text-base mb-3">Speak now</p>
                <MicrophoneTestComponent />
              </Dialog.Title>
              <div className="mt-3 flex flex-col justify-center">
                <Heading level={4}> Is microphone working for you? </Heading>
                <div className="flex gap-3 justify-center mt-4">
                  <Button
                    className="border-primaryLight hover:bg-primary hover:text-white"
                    onClick={() => handleMicrophoneModalClose(true)}
                  >
                    Yes
                  </Button>
                  <Button
                    className="border-primaryLight hover:bg-primary hover:text-white"
                    onClick={() => handleMicrophoneModalClose(false)}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default SettingsComponent;
