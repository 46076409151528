import { useState } from "react";
import { Dialog } from "@headlessui/react";
import {
  Bars3Icon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../Store/store";
import { GetUser } from "../../Features/Auth/userSlice";
import LogoutButton from "../Logout";
import { USER_ROLES } from "../../Constants/roles";

export default function Header() {
  const { data: user } = useAppSelector(GetUser);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const userRole = user?.gtUserData?.gtUserType

  return (
    <header className="bg-white shadow-sm">
      <nav
        className="mx-auto flex max-w-screen-2xl items-center justify-between gap-x-6 p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Good thoughts</span>
            <img className="h-8 w-auto" src="/images/gt-logo.png" alt="" />
          </Link>
        </div>
        <div className="flex flex-1 items-center justify-end gap-x-6">
          {!user && (
            <Link
              to="/login"
              className="transition-all rounded-md px-3 py-2 lg:text-sm font-semibold lg:text-secondary hover:bg-primary hover:text-white focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              Log in
            </Link>
          )}
          {user ? (
            <LogoutButton />
          ) : (
            <Link
              to="/register"
              className="transition-all hidden lg:block rounded-md text-secondary px-3 py-2 text-sm font-semibold  hover:bg-primary hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              Register
            </Link>
          )}
        </div>
      </nav>
    </header>
  );
}
